import { Chain } from '../models/chain.model';

type ChainIds = Partial<Record<Chain, string>>;

export const enum PoolPercentAlerts {
  WARNING = 10,
  ALERT = 15,
}

export const enum PoolPercentAlertsNewToken {
  WARNING = 3,
  ALERT = 6,
}

/**
 * Chains supported by Bubblemaps and the nomenclature on CHAINNAME app.bubblemaps.io
 * https://app.bubblemaps.io/CHAINNAME/
 */
export const chainBubbleMaps: Partial<ChainIds> = {
  [Chain.Ethereum]: 'eth',
  [Chain.Binance]: 'bsc',
  [Chain.Avalanche]: 'avax',
  [Chain.Fantom]: 'ftm',
  [Chain.Cronos]: 'cro',
  [Chain.Arbitrum]: 'arbi',
  [Chain.Polygon]: 'poly',
  [Chain.Base]: 'base',
  [Chain.Solana]: 'sol',
};

export const pairExplorerQueryParams = {
  timestamp: 't',
  forceRepresentativePairSearch: 'q',
  maker: 'maker',
  news: 'news',
};

export const regexTokenDefaultUrl = new RegExp(`.*/token/.*`);
